import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Assembly - Attaching the Cross Rafters"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Step 5. Attaching the Cross Rafters`}</h1>
    <p><strong parentName="p">{`a) The Ledger Board`}</strong></p>
    <p>{`If you have not already attached the joist hangers to the ledger board in Step 3, the first task is to fix them to the non-decorated end of the cross rafters. Use the two fixing holes (one triangular the other circular) on either sides of the joist hangers to attach them to the rafter with the 1inch (25mm) non recessed wood screws provided. As you have already 'marked-up' the positions of the joist hangers previously, when the ledger board was being prepared, it is important that you follow the spacings between the hangers and make sure that when you offer up the joist hanger end that it is placed exactly in between the 40mm markings you have already made as this will ensure that the final structure is aligned perfectly.`}</p>
    <p>{`If you have order one of our larger modules (MOD2 or MOD3) the joist hanger will 'straddle' the line where the two non-decorated ends of the ledger board (MOD2) or the non-decorated extension pieces (MOD3) butt up against one another. So, you will fix, with 2 screws, one side of the joist hanger to the first ledger board and the other side of the joist hanger will be fixed (again with 2 screws) to the second ledger board (MOD2) or extension board (MOD3) and so on.`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-5-attaching-the-cross-rafters/n-fig18.jpg" alt="A hand-drawn diagram demonstrating to attach the joist hangers to the ledger board. There should be 680mm between each joist." />
  <span className="text-metadata">Fig 18</span>
    </div>
    <p>{`Lift up the first rafter (you will need a second pair of hands here to help!) and resting the decorated end on the Main beam, offer up the joist hanger end to the pre-marked ledger board and when correctly aligned screw in using the 1inch (25mm) non recessed wood screws provided. You only need to use 2 screws per side to provide a full and firm fixing. Using both your spirit level and builder’s square, make sure that each rafter is ‘square’ with relation to the ledger board before final fixing.`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-5-attaching-the-cross-rafters/fig-1999.jpg" alt="A hand-drawn diagram demonstrating a side view of the assembled rafter, ledgerboard, spacers, and joist hanger." />
  <span className="text-metadata">Fig 19</span>
    </div>
    <p><strong parentName="p">{`a) The Ledger Board`}</strong></p>
    <p>{`The rafter(s) are lying on top of the main beam so before fixing ensure that they are at right angles to the wall and main beam itself by using your builders square. You can also double check by making sure that the measurement between each rafter is the same 680mm at the main beam end as it is at the ledger board. Start off by fixing the outer rafter first and make sure that the decorated end is positioned centrally over the post. Once done, fix the rafters to the main beam using the right-angle brackets and the 25mm recessed screws provided. Although the angle brackets have 3 fixing holes per side you only need to use 2 of them to fix to the main beam and 1 to fix to the rafter to achieve a firm fixing. A good tip here is when fixing the right angled bracket to the rafter use different holes on either side to ensure that the screws do not foul in the middle. See Fig 20 & 21 below`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-5-attaching-the-cross-rafters/fig200.jpg" alt="A diagram demonstrating the ideal positioning of the rafters onto the main beam. The rafters are positioned no less than 250mm from the ends of the beam, and evenly spaced 680 apart. There should be 2060mm between the main posts." />
  <span className="text-metadata">Fig 20</span>
    </div>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-5-attaching-the-cross-rafters/n-fig21.jpg" alt="A diagram demonstrating a top-down view of the example given in fig 20" />
  <span className="text-metadata">Fig 21</span>
    </div>
    <p>{`Fig 22 & 22A below show that the rafters fix centrally to the tops of the post and give further structural support when screwed in place.`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-5-attaching-the-cross-rafters/n-fig22.jpg" alt="A diagram demonstrating how to fix the rafter to the main post, for 9ft x 16ft and 9ft x 23ft pergolas. The rafters should be attached to the top face of the main beam using angle brackets and the provided wood screws." />
  <span className="text-metadata">Fig 22</span>
    </div>
    <div className="captioned">
  <img src="/img/pergola-assembly/step-5-attaching-the-cross-rafters/fig22a.jpg" alt="A top-down diagram of the rafter to main beam attachment described in fig 22" />
  <span className="text-metadata">Fig 22a</span>
    </div>
    <p><em parentName="p">{`(`}{`*`}{`NB these drawings are for representation purposes only and not to scale)`}</em></p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      